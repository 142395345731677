import "./App.css";
import "./styles/main.css";
import { useState, useEffect } from "react";
import { shortenAddress } from './commons/utils'
// import { useWeb3React } from "@web3-react/core";
// import { injected } from "./wallet/Connector";
import { useWallet, UseWalletProvider } from 'use-wallet'
import ModalConnect from './components/ModalConnect'
import Web3 from "web3"
import {recoverTypedSignature} from "@metamask/eth-sig-util"
import Raffle from "./components/Raffle"
import axios from "axios";
import DEFAULTS from "./commons/global";


function App() {
  const [allRaffles, setAllRaffles] = useState([]);
  const [allEntries,setAllEntries]= useState({});
  const [walletEntries, setWalletEntries] = useState([])
  const [bears,setBears] = useState([])
  const wallet = useWallet()

    const fetchRaffles = async() =>{
        await axios.get(`${DEFAULTS.API}raffles/getAllRaffles`, {})
        .then(res => {
            setAllRaffles(res.data);
            // console.log(res.data)
        }).catch(err => {
          console.log(err)
        });
    }
    const fetchEntries = async() =>{
        await axios.get(`${DEFAULTS.API}entries/getAllEntries`, {})
        .then(res => {
            setAllEntries(res.data)
        }).catch(err => {
            console.log(err)
        });
    }
    const fetchBears = async(publicKey) => {
      await axios.post(`${DEFAULTS.API}bearsinwallet`, {publicKey})
        .then(res => {
            setBears(res.data)
            // console.log(res.data)
        }).catch(err => {
            console.log(err)
        });
    }
    const fetchWalletEntries = async(publicKey) =>{
      await axios.post(`${DEFAULTS.API}entries/getEntries`, {publicKey})
      .then(res => {
          setWalletEntries(res.data)
          // console.log("walletentries",res.data)
      }).catch(err => {
          console.log(err)
      });
    }

    useEffect(() => {
        fetchRaffles()
        fetchEntries()
    }, []);

    useEffect(() => {
      if(wallet.account){
        fetchBears(wallet.account)
        fetchWalletEntries(wallet.account)
      }
    }, [wallet.account])


  async function sign(){
    const { ethereum } = wallet;
    let web3 = new Web3(ethereum);

    const msgParams = [
      {
        type: 'string',      // Any valid solidity type
        name: 'Message',     // Any string label you want
        value: 'Hi, Alice!'  // The value to sign
     }
    ] 
    // console.log("wallet:",wallet.account)
    const method = (wallet.connector !== 'injected' ? 'eth_signTypedData_v1':'eth_signTypedData')

    // console.log(ethereum)
    signMsg(msgParams, wallet.account) 


    // helper
    function signMsg(msgParams, from) {
      // console.log(ethereum.sendAsync)
      ethereum.request({
        method,
        params: [msgParams, from],
        from: from,
      }, function (err, result) {
        // console.log("err",err)
        // console.log("result",result)
        if (err) return console.error(err)
        if (result.error) {
          return console.error(result.error.message)
        }
        const recovered = recoverTypedSignature({
          data: msgParams,
          signature: result.result,
          version:"V1"
        })
        // console.log(recovered)
        if (recovered.toLowerCase() === from.toLowerCase() ) {
          alert('Recovered signer: ' + from)
        } else {
          alert('Failed to verify signer, got: ' + result)
        }
      })
    }
  }

  return (
    <div className="mb-28">
      <img
        src="images/nav-bg-1.png"
        loading="lazy"
        height=""
        sizes="(max-width: 3346px) 100vw, 3346px"
        srcSet="images/nav-bg-1-p-500.png 500w, images/nav-bg-1-p-800.png 800w, images/nav-bg-1-p-1080.png 1080w, images/nav-bg-1.png 3346w"
        alt=""
        className="image-35"
      ></img>
      <nav className="px-2 sm:px-4 py-4 mb-5">
        <div className="container flex flex-wrap justify-between items-center mx-auto">
          <a href="/" className="brand-2 w-nav-brand">
            <img
              src="images/site-logo-1.png"
              sizes="197.78125px"
              height="45"
              srcSet="images/site-logo-1-p-500.png 500w, images/site-logo-1-p-800.png 800w, images/site-logo-1.png 1912w"
              alt=""
              className="image-36"
            />
          </a>
          <div className="flex md:order-2 items-center">
          {!wallet.isConnected() ? (
            // <button
            //   type="button"
            //   className="text-black bg-white ring-4 ring-black hover:bg-pink-300 py-3 rounded-lg px-3 text-center mr-3 md:mr-0"
            //   onClick={() => wallet.connect()}
            // >
            //   Connect
            // </button>
            <ModalConnect/>
            ):(<>
              <div className="mr-3">
              <span className="inline-flex items-center p-1 mr-2 text-sm font-semibold text-green-800 bg-green-300 rounded-full">
                <svg className="w-3 h-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path></svg>
              </span>
              {shortenAddress(wallet.account)}
              </div>
              <button
              type="button"
              className="text-black bg-white ring-4 ring-black hover:bg-pink-300 py-3 rounded-lg px-3 text-center mr-3 md:mr-0"
              onClick={() => wallet.reset()}
              >
                Disconnect
              </button></>
            )
            }
          </div>
        </div>
      </nav>
      {
          allRaffles.sort((a,b)=>(new Date(b.endDate))-(new Date(a.endDate))).map(raffle => {
            return <Raffle 
              key={raffle._id} 
              raffle={raffle} 
              wallet={wallet} 
              sign={sign} 
              bears={bears}
              allEntries={allEntries[raffle._id]}
              entries={walletEntries.length ? walletEntries.filter(e=>(e.raffleId===raffle._id))[0] : null}
              refreshEntries={fetchWalletEntries}
              />
      })
      }
    </div>
  );
}

// Wrap everything in <UseWalletProvider />
export default function WrappedApp() {
  return (
    <UseWalletProvider
      chainId={1}
      connectors={{
        walletconnect: {
          rpc: {
            1: "https://mainnet.infura.io/v3/2f22752062a046f5805d600586d86fbd",
          },
        },
        walletlink: {
          chainId: 1,
          url: "https://www.inbetweeners.io/",
          appName: "inBetweeners Raffles",
          appLogoUrl: "https://www.inbetweeners.io/images/webclip.png",
        },
      }}
    >
      <App />
    </UseWalletProvider>
  );
}
  
