import { useState } from "react";
import axios from "axios";
import DEFAULTS from "../commons/global";
import { bufferToHex } from "ethereumjs-util";


const ClaimModal = ({ handleClose, show, nonce, wallet, raffleId }) => {
  const showHideClassName = show ? "" : "hidden ";
  const [verified, setVerified] = useState(false);

  const sign = async (e) => {
    e.preventDefault();
    const form = document.querySelector("form");
    const { discordId } = Object.fromEntries(new FormData(form).entries());

    try{
        if (!wallet.account) throw new Error('Wallet not connected!');

        const { ethereum } = wallet;
    var buf = Buffer.from(
      JSON.stringify({ discordId, nonce })
    );
    var msg = bufferToHex(buf);
    signMsg(msg, wallet.account);

    // helper
    function signMsg(msgParams, from) {
      // console.log(ethereum.sendAsync)
      ethereum.sendAsync(
        {
          method: "personal_sign",
          params: [msgParams, from],
          from: from,
        },
        function (err, result) {
        //   console.log("err", err);
        //   console.log("result", result);
          if (err) return console.error(err);
          if (result.error) {
            return console.error(result.error.message);
          }
          //AXIOS POST
          axios
            .post(`${DEFAULTS.API}winners/verify`, {
              publicKey: from,
              signedData: msgParams,
              signature: result.result,
              raffleId
            })
            .then((res) => {
                setVerified(true)
            })
            .catch((err) => {
              // setError(true)
              console.log(err);
            });
        }
      );
    }
    }catch(err){
        console.log(err)
    }
  };

  return (
    <>
      <div
        className={
          showHideClassName +
          "mx-auto fixed inset-0 flex items-center overflow-y-auto overflow-x-hidden fixed top-4 z-50 justify-center h-modal"
        }
      >
        <div className="relative px-4 w-full max-w-md h-full md:h-auto">
          <div className="relative bg-white rounded-lg shadow">
            <div className="flex justify-end p-2">
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                onClick={() => handleClose(false)}
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            {!verified && (
              <form
                className="px-6 pb-4 space-y-6 lg:px-8 sm:pb-6 xl:pb-8"
                // onSubmit={sign}
              >
                <h3 className="text-xl font-medium text-gray-900">
                  Discord Username
                </h3>
                <div>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    (including #)
                  </label>
                  <input
                    type="text"
                    name="discordId"
                    id="discordId"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Username"
                    required={true}
                  />
                </div>
                <button
                  type="submit"
                  onClick={sign}
                  className="p-3 ring-4 ring-black hover:bg-pink-300 hover:opacity-90 text-black rounded-lg"
                >
                  Claim
                </button>
              </form>
            )}
            {verified && (
              <>
                <h3 className="text-xl font-medium text-gray-900">
                  🎉 LFG! 🎉
                </h3>
                <div className="p-5">Thanks for Verifying</div>
              </>
            )}
          </div>
        </div>
      </div>
      <div
        className={showHideClassName + "fixed top-0 left-0 w-full h-full"}
        style={{ background: "rgba(0, 0, 0, 0.1)" }}
      ></div>
    </>
  );
};
export default ClaimModal;
