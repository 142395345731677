import React, { useEffect, useState } from "react";
import Timer from "./Timer";
import { bufferToHex } from "ethereumjs-util";
import axios from "axios";
import DEFAULTS from "../commons/global";
import Winners from "./Winners";

export default function Raffle({ raffle, wallet, bears, entries, allEntries, refreshEntries }) {
  const raffleEnd = new Date(raffle.endDate);
  const currentDate = new Date();
  const [showWinners,setShowWinners] = useState(false)
  async function sign(raffleId, participatingBears) {
    const { ethereum } = wallet;
    var buf = Buffer.from(
      JSON.stringify({
        raffleId,
        bears: participatingBears,
      })
    );
    var msg = bufferToHex(buf);
    signMsg(msg, wallet.account);

    // helper
    function signMsg(msgParams, from) {
      // console.log(ethereum.sendAsync)
      ethereum.sendAsync(
        {
          method: "personal_sign",
          params: [msgParams, from],
          from: from,
        },
        function (err, result) {
        //   console.log("err", err);
        //   console.log("result", result);
          if (err) return console.error(err);
          if (result.error) {
            return console.error(result.error.message);
          }
          //AXIOS POST
          axios
            .post(`${DEFAULTS.API}entries/createEntry`, {
              publicKey: from,
              signedData: msgParams,
              signature: result.result,
            })
            .then((res) => {
            //   console.log(res.data);
              refreshEntries(wallet.account)
            })
            .catch((err) => {
              // setError(true)
              console.log(err);
            });
        }
      );
    }
  }

  return (
    <div className="container mx-auto mt-28 max-w-5xl rounded bg-gray-700 ring-4 ring-black text-xl">
      <div className="text-center text-white relative pb-5">
        <div className="inline-flex items-center absolute top-4 right-4">
        {raffleEnd > currentDate ? (
            <>
            <Timer raffle={raffle} enableText={true} center={true} />
          <span className="inline-flex items-center justify-center ml-3 px-3 py-3 text-xl font-bold leading-none text-green-100 bg-green-600 rounded-full">
            Open
          </span>
          </>):(
              <span className="inline-flex items-center justify-center px-3 py-3 text-xl font-bold leading-none text-red-100 bg-red-600 rounded-full">Closed</span>
          )
        }
        </div>
        <img
          className="inline object-cover mt-28 md:mt-5 w-36 h-36 mr-2 rounded-full"
          src={raffle.image}
          alt=""
        />
        <h2 className="pt-6 text-4xl">{raffle.name}</h2>
        <div className="flex wrap justify-center items-start mb-4">
          {raffle.website && (
            <a
              className="m-2 mr-5 justify-center items-center"
              target="_blank"
              rel="noreferrer"
              href={raffle.website}
            >
              <img
                className="rounded"
                width={18}
                height={18}
                src="/images/www.png"
                alt="Website Logo"
              />
            </a>
          )}
          {raffle.discord && (
            <a
              className="m-2 mr-5 justify-center items-center"
              target="_blank"
              rel="noreferrer"
              href={raffle.discord}
            >
              <img
                className="rounded"
                width={20}
                height={20}
                src="/images/discord.svg"
                alt="Discord Logo"
              />
            </a>
          )}
          {raffle.twitter && (
            <a
              className="m-2 mr-5 justify-center items-center"
              target="_blank"
              rel="noreferrer"
              href={raffle.twitter}
            >
              <img
                className="rounded"
                width={20}
                height={20}
                src="/images/twitter.svg"
                alt="Twitter Logo"
              />
            </a>
          )}
        </div>
        <div className="text-xl mx-10" style={{whiteSpace: "pre-wrap"}}>{raffle.description}</div>
        <div className="text-3xl py-5">{raffle.supply} Winner{raffle.supply>1 ? "s":""}</div>
        <div className="inline-flex pb-5">
          <div className="pr-3 border-r border-white">1 🧸 = 1 🎟️</div>
          <div className="ml-3"> 🎟️ {allEntries ? allEntries : 0} claimed</div>
        </div>

        {/* <div
            className="text-black bg-white ring-4 ring-black hover:bg-pink-300 py-3 mb-5  rounded-lg px-3 text-center mr-3 md:mr-0 my-4 w-100"
          >
            Your tickets: 
          </div> */}

        {
            (raffleEnd > currentDate) && !wallet.isConnected() &&
            <div className="text-md pb-5">Please connect your wallet</div>
        }

        { ((raffleEnd > currentDate) && !entries && wallet.isConnected()) &&
          <div className="mx-auto">
            <button
              type="button"
              className="text-black bg-white ring-4 ring-black hover:bg-pink-300 py-3 rounded-lg px-3 text-center mr-3 md:mr-0 mb-5"
              onClick={() => sign(raffle._id, bears)}
            >
              Participate
            </button>
            <div className="text-sm">
              ({bears.length} bear{bears.length>1?"s":""} in your wallet)
            </div>
          </div>
        }
        {
            ((raffleEnd > currentDate) && entries && entries.quantity && wallet.isConnected()) &&
            <div className="mx-auto">
                <button
                type="button"
                className="text-black bg-white ring-4 ring-black hover:bg-pink-300 py-3 rounded-lg px-3 text-center mr-3 md:mr-0 mb-5"
                >
                🎉 You have {entries.quantity} 🎟️
                </button>
            </div>
        }
        {
            raffleEnd <= currentDate &&
            <div className="mx-auto">
                <button
                type="button"
                className="text-black bg-white ring-4 ring-black hover:bg-pink-300 py-3 rounded-lg px-3 text-center mr-3 md:mr-0 mb-5"
                onClick={()=>setShowWinners(!showWinners)}
                >
                Check Winners 🎉
                </button>
                <Winners winners={raffle.winners} raffle={raffle} wallet={wallet} show={showWinners}/>
            </div>
        }
      </div>
    </div>
  );
}
