import { useState, useEffect } from "react";

const Timer = (props) => {
    const {
        raffle,
        auction,
    } = props;

    const timerItem = raffle || auction;

    const raffleEnd = new Date(timerItem.endDate);
    const currentDate = new Date();

    const calculateTimeLeft = () => {
        const difference = raffleEnd-currentDate;
        let timeLeft = {};
        if (raffleEnd > currentDate) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }
        return timeLeft;
    }
    const [ timeLeft, setTimeLeft ] = useState(calculateTimeLeft());

    useEffect(() => {
        setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
    });

    return (
        <div>
        {
            Object.keys(timeLeft).length === 0 || (timeLeft.days === 0 && timeLeft.hours === 0 && timeLeft.minutes === 0 && timeLeft.seconds === 0) ? null :
            <div className={(props.center? 'text-center': '') + (props.big? ' text-xl':'')}>
                {`${props.enableText? 'Ends in:': ''} ${timeLeft.days}d ${timeLeft.hours}h ${timeLeft.minutes}m ${timeLeft.seconds < 10 ? `0${timeLeft.seconds}` : timeLeft.seconds}s`}
            </div> 
        }
        </div>
    )
}

export default Timer;