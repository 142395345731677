import { useState } from "react";
import axios from "axios";
import DEFAULTS from "../commons/global";
import ClaimModal from "./ClaimModal";

export default function Winners({ raffle, winners, wallet, show }) {
  const [showModal, setShowModal] = useState(false);
  const [nonce, setNonce] = useState();

  const claim = async () => {
    setShowModal(true);
    await axios
      .post(`${DEFAULTS.API}winners/login`, {
        publicKey: wallet.account,
      })
      .then((res) => {
        setNonce(res.data.nonce);
        // console.log(res.data.nonce)
      })
      .catch((err) => {
        console.log(err);
      });
  };
//   console.log("wallet winner", wallet.account);
  return (
    <div
      className={
        "justify-center overflow-auto m-5 mx-auto" + (show ? "" : " hidden")
      }
    >
      <table className="table-auto text-center mx-2 md:mx-auto rounded-lg shadow-lg bg-white text-black mb-5">
        <thead>
          <tr className="p-5">
            <th className="p-7 underline">Wallet</th>
            <th className="p-7 underline">Entries</th>
            {raffle.category !== "NFT" && (
              <th className="p-7 underline">Claim</th>
            )}
          </tr>
        </thead>
        <tbody>
          {winners.map((winner) => {
            return (
              <tr key={winner.wallet}>
                <td className="p-7">{winner.wallet}</td>
                <td className="p-7">{winner.entries}</td>
                {raffle.category !== "NFT" && (
                  <td className="flex justify-center items-center p-7">
                    {wallet.account &&
                      winner.wallet.toLowerCase() ===
                        wallet.account.toLowerCase() &&
                      !winner.claimed && (
                        <>
                          <button
                            type="button"
                            className="text-black bg-white ring-4 ring-black hover:bg-pink-300 py-3 rounded-lg px-3 text-center mr-3 md:mr-0"
                            onClick={claim}
                          >
                            Claim
                          </button>
                          <ClaimModal
                            show={showModal}
                            handleClose={setShowModal}
                            nonce={nonce}
                            wallet={wallet}
                            raffleId={raffle._id}
                          />
                        </>
                      )}
                    {winner.claimed && (
                      <div className="flex justify-center p-2 text-depurple rounded-lg">
                        Claimed
                      </div>
                    )}
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
